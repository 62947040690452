import { RetailMediaBanner as BaseRetailMediaBanner } from '@/presenters/components/retail-media';

interface RetailMediaBannerProps {
  slug: string;
}

export default function RetailMediaBanner(
  props: Readonly<RetailMediaBannerProps>
) {
  const { slug } = props;

  // Está fixo só para home, necessita analisar os contextos em caso de novos modulares com necessidade de retail.
  if (!['home', 'home-app'].includes(slug)) {
    return null;
  }

  return <BaseRetailMediaBanner context={{ name: 'home', size: '980_120' }} />;
}
