import { RetailMediaProductsCarousel as BaseRetailMediaProductsCarousel } from '@/presenters/components/retail-media';

interface RetailMediaProductsCarouselProps {
  title: string;
  slug: string;
}

export default function RetailMediaProductsCarousel(
  props: Readonly<RetailMediaProductsCarouselProps>
) {
  const { slug, title } = props;

  // Está fixo só para home, necessita analisar os contextos em caso de novos modulares com necessidade de retail.
  if (!['home', 'home-app'].includes(slug)) {
    return null;
  }

  return (
    <BaseRetailMediaProductsCarousel context={{ name: 'home' }} title={title} />
  );
}
